import { AuthType } from '@helpers/AuthClient';
import ResendVerifyEmailView from '@views/ResendVerifyEmailView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface LocationState {
  email?: string;
}

const ResendCodeAppPage: FC<PageProps<null, null, LocationState | null>> = ({
  location: { state },
}) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ResendVerifyEmailView authType={AuthType.App} defaultValues={{ email: state?.email }} />
    </>
  );
};

export default ResendCodeAppPage;
